<template>
  <div>
    <v-fab-transition>
      <v-btn
        v-show="!show.sidebar"
        @click="changeMenu()"
        color="#034f79"
        fab
        dark
        small
        top
        left
      >
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-fab-transition>
      <v-sheet v-show="show.sidebar" rounded="lg">
        <v-list color="#E95233" dark>
          <v-btn @click="hideSidebar()" color="#fff" small text block>
            <v-icon small class="mr-1">mdi-arrow-left</v-icon>

            Ocultar</v-btn
          >
          <v-divider class="my-2"></v-divider>
          <v-list-item
            v-for="item in listItems"
            :key="item.id"
            link
            :to="{ name: item.routeName }"
          >
            <v-list-item-content>
              <v-list-item-title> {{ item.text }} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-sheet>
    </v-fab-transition>
  </div>
</template>
<script>
import { NAVIGATION } from "./../utils/util";
import alert from "@/mixins/alert";
import store from "./../store/index";
export default {
  mixins: [alert],
  name: "Sidebar",
  data() {
    return {
      listItems: [],
      rol: null,
      show: {
        sidebar: false,
      },
    };
  },
  methods: {
    changeMenu() {
      this.show.sidebar = true;
      this.$emit("showMenu", false);
    },
    hideSidebar() {
      if (this.show.sidebar) {
        this.show.sidebar = false;
        this.$emit("showMenu", true);
      } else {
        this.show.sidebar = true;
      }
    },
    menu() {
      // var rol = store.getters.getInfoUser;
      var menu = [];
      switch (this.rol) {
        case 4:
          menu = NAVIGATION.workforce;
          break;
        case 1:
          menu = NAVIGATION.directory;
          break;
        case 2:
          menu = NAVIGATION.manager;
          break;
        case 3:
          menu = NAVIGATION.payRollManager;
          break;
        case 5:
          menu = NAVIGATION.coordinator;
          break;
        case 6:
          menu = NAVIGATION.accountManager;
          break;
        default:
          break;
      }
      this.listItems = menu;
    },
  },
  created() {
    this.rol = parseInt(atob(localStorage.getItem('dXNlclJvbGU=')));
  },
  mounted() {
    this.menu();
  },
};
</script>

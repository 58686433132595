<template>
   <v-footer class="color-container-main mt-5" absolute padless>
    <v-col
      class="text-center"
      cols="12"
    >
     <label class="color-text-light"> {{ new Date().getFullYear() }} —  <strong> AUTOCOM </strong> </label>
    </v-col>
  </v-footer>
</template>
<script>
export default {
  name: 'FooterUser'
}
</script>

<template>
  <v-app id="inspire">
    <NavbarUser></NavbarUser>
    <v-main class="grey lighten-3 mb-12">
        <v-row class="mx-6 mt-3 mb-6"> 
          <v-col :cols="flex">
           <Sidebar @showMenu="changeFlex($event)"></Sidebar>
          </v-col>
          <v-col >
            <v-sheet min-height="75vh" rounded="lg">
              <transition>
                <router-view></router-view>
              </transition>
            </v-sheet>
          </v-col>
        </v-row>
    </v-main>
    <FooterUser></FooterUser>
  </v-app>
</template>
<script>
import NavbarUser from "./../../components/NavbarUser";
import FooterUser from "./../../components/FooterUser";
import Sidebar from '../../components/Sidebar';

export default {
  name: "Layout",
  components: {
    NavbarUser,
    FooterUser,
    Sidebar,
  },
  data() {
    return {
      flex: 1,
    };
  },
  methods: {
    changeFlex(e){
      if(e) {
        this.flex = 1; 
      } else {
        this.flex = 2;
      }
    }
  }
};
</script>
<style lang="less">
@import "../../assets/css/main";
</style>

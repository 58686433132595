<template>
  <v-app-bar class="pl-4 pr-3" app color="white" flat>
    <v-btn small color="#E95233" @click="$router.go(-1)" fab icon>
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <v-avatar>
      <img src="../assets/img/moda.png" alt="perfil" />
    </v-avatar>
    <label class="mt-2 ml-5 color-text-main" style="text-transform: uppercase">
      <strong> {{ role }} </strong>
    </label>
    <v-spacer></v-spacer>

    <v-menu
      offset-y
      bottom
      origin="center center"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click="getNotification()"
          color="#E95233"
          v-bind="attrs"
          v-on="on"
          fab
          icon
        >
          <v-badge
            color="#034f79"
            :content="messages"
            :value="messages"
            overlap
          >
            <v-icon color="#E95233"> mdi-bell </v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list max-width="350" max-height="450">
        <v-list-item class="py-0">
          <v-layout v-if="items.length > 0" justify-end> 
          <v-btn @click="clear()" dark text small color="#034f79"> <strong> <u> Limpiar </u> </strong> </v-btn>  
          </v-layout>
        </v-list-item>
        <v-list-item-group>
          <template v-for="(item, i) in items">
            <v-list-item
              :class="item.class"
              :key="i"
              @click="read(item.commission_id, item.id, item.status_id)"
            >
              <v-list-item-title
                style="text-align: initial; white-space: initial"
                color="#8eb8d0"
                >{{ item.title }}
              </v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
          </template>
        </v-list-item-group>
      </v-list>
    </v-menu>

    <v-btn
      :loading="loading.btn"
      @click="logout()"
      class="ml-2"
      color="#E95233"
      fab
      icon
    >
      <v-icon>mdi-logout</v-icon>
    </v-btn>
  </v-app-bar>
</template>
<script>
import alert from "@/mixins/alert";
import Api from "@/utils/api";
import store from "./../store/index";
import { STATUS, ROL } from "./../utils/util";
export default {
  mixins: [alert],
  name: "NavbarUser",
  data() {
    return {
      STATUS: STATUS,
      ROL: ROL,
      role: "",
      rolId: null,
      items: [],
      loading: {
        btn: false,
      },
      messages: 0,
    };
  },
  methods: {
    clear(){
      axios.put('api/notifications/updateStatus/delete').then(res => {
        if(res.data.cod  == 0 ){
          this.alert(res.data.message, "Muy bien", "success");
          this.getNotification();
        } else {
          this.alert(res.data.message, "Oops...", "error");
        }
      }).catch(error => {
        this.alert(error.response.data.message, "Oops...", "error");
      })
    },
    read(commissionId, id, status) {
      var data = {
        id: id,
      };
      if (status == STATUS.noRead) {
        Api.Auth()
          .readNotification(data)
          .then((res) => {
            if (res.data.cod == 0) {
              this.redirectRole(commissionId);
              this.getNotification();
              this.alert(res.data.message, "Muy bien", "success");
            } else {
              this.alert(res.data.message, "Oops...", "error");
            }
          })
          .catch((error) => {
            this.alert(error.response.data.message, "Oops...", "error");
          })
          .finally(() => (this.loading.btn = false));
      } else {
        this.redirectRole(commissionId);
      }
    },
    redirectRole(commissionId) {
      var rol = parseInt(this.rolId);
      switch (rol) {
        case 4:
          this.$router.push({
            name: "commissionDetails",
            params: { commissionId: commissionId },
          });
          break;
        case 1:
          this.$router.push({
            name: "commissionDetailsDirectory",
            params: { commissionId: commissionId },
          });
          break;
        case 2:
          this.$router.push({
            name: "commissionDetailsManager",
            params: { commissionId: commissionId },
          });
          break;
        case 3:
          this.$router.push({
            name: "commissionsPayRollManager",
            params: { commissionId: commissionId },
          });
          break;
        default:
          break;
      }
    },
    getNotification() {
      Api.Auth()
        .getNotification()
        .then((res) => {
          this.items = res.data.data;
          var cont = 0;
          for (let i = 0; i < this.items.length; i++) {
            if (this.items[i].status_id == STATUS.noRead) {
              cont++;
              this.items[i].class = "in-active-no py-2";
            } else {
              this.items[i].class = "active-no py-2";
            }
          }
          this.messages = cont;
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => (this.loading.btn = false));
    },
    logout() {
      Api.Auth()
        .logout()
        .then((res) => {
          this.$router.push({ name: "login" });
          this.alert(res.data.message, "Muy bien", "success");
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => (this.loading.btn = false));
    },
  },
  created() {
    this.role = atob(localStorage.getItem("dXNlclJvbGVOYW1l"));
    this.rolId = atob(localStorage.getItem("dXNlclJvbGU="));
  },
  mounted() {
    this.getNotification();
  },
};
</script>
<style scoped>
.in-active-no {
  background-color: aliceblue !important;
}

.active-no {
  background-color: #fff !important;
}
</style>
